import { useEffect, useState, useRef } from 'react'
import BannerApi from '../../../api/banners'
import { getFileFullPath } from '../../../utils/Helpers'
import styles from './BannerModal.module.scss'
import { actions } from '../../../store'
import { connect } from 'react-redux'
import useInterval from '../../../lib/useInterval'
import { setCookies, checkCookies } from 'cookies-next'
import config from '../../../config.json'

interface BannerModalProps {
  setShowBanner: Function
  modalHidden: boolean
  showBanner: boolean
}

const BannerModal = ({
  setShowBanner,
  modalHidden,
  showBanner,
}: BannerModalProps) => {
  const [showModal, setShowModal] = useState(false)
  const [displayModal, setDisplayModal] = useState(true)
  const [bannerData, setBannerData] = useState({
    banner_image: '',
    banner_image_mobile: '',
    banner_url: ''
  })

  const bannerRef = useRef(null)

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     const hasVisitedBefore = localStorage.getItem('visitedBefore')
  //     if (hasVisitedBefore !== 'true') {
  //       localStorage.setItem('visitedBefore', 'true')
  //       setShowModal(true)
  //     }
  //   }
  // }, [])

  function disableScrolling() {
    document.body.style.overflow = 'hidden'
    document.body.style.position = 'relative'
    document.body.style.touchAction = 'none'
  }

  function enableScrolling() {
    document.body.style.overflow = 'auto'
    document.body.style.touchAction = 'auto'
  }

  useEffect(() => {
    if (!modalHidden && showModal && showBanner) {
      window.scrollTo(0, 0)
      disableScrolling()
    } else {
      enableScrolling()
    }
  }, [modalHidden, showModal, showBanner])

  const getBanner = async (): Promise<void> => {
    const bannerRes = await BannerApi.getCoverBanner()
    if (Object.keys(bannerRes.data).length === 0) {
      return setShowModal(false)
    }
    const bannerViewed = checkCookies(null, 'bannerViewed')
    if (!bannerViewed) {
      setCookies(null, 'bannerViewed', 'true', { maxAge: 60 * 30 })
      setShowModal(true)
      setShowBanner(true)
      setBannerData(bannerRes.data)
    }
  }

  useEffect(() => {
    getBanner()
  }, [])

  useEffect(() => {
    if (modalHidden) {
      setTimeout(() => {
        setDisplayModal(false)
      }, 200)
    }
  }, [modalHidden])

  useInterval(() => {
    if (bannerRef.current && showModal && showBanner) {
      window.scrollTo(0, 0)
    }
  }, 100)

  if (!showModal || !displayModal) {
    return null
  } else {
    return (
      <div
        style={{
          paddingTop:
            config.env !== 'production' && window.innerWidth < 400
              ? 108
              : config.env !== 'production'
              ? 84
              : 60,
        }}
        className={`${styles.BannerModal} ${
          modalHidden ? styles.HideBanner : styles.ShowBanner
        } ${displayModal ? '' : styles.DisplayNone}`}
        ref={bannerRef}
      >
        <a
          href={bannerData.banner_url} 
          onClick={(e) => {
            if (bannerData.banner_url === '') {
              e.preventDefault()
            }
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={styles.BannerModalImage}
            onLoad={() => window.scrollTo(0, 0)}
            src={getFileFullPath(
              window.innerWidth > 750
                ? bannerData.banner_image
                : bannerData.banner_image_mobile
            )}
          />
        </a>
      </div>
    )
  }
}

// showBanner is meant to tell the header what UI it should use,
// depending on if the banner is being shown or not

const mapStateToProps = (state) => ({
  modalHidden: state.bannerModalHidden,
  showBanner: state.showBanner,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setShowBanner: (showBannerArg) => {
      dispatch({
        type: actions.SET_SHOW_BANNER,
        payload: {
          showBanner: showBannerArg,
        },
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerModal)
