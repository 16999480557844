import { connect } from 'react-redux'

import styles from './SignInIcon.module.scss'

const SignInIcon = ({ displaySignupForm }) => (
  <div
    className={`${styles.wrapper} flex items-center ml-8 cursor-pointer`}
    onClick={displaySignupForm}
  >
    <div
      className={`${styles.text} rounded-3xl pl-3 pr-10 h-6 items-center hidden lg:flex`}
    >
      <p className="text-white">Prijava</p>
    </div>
    <div
      className={`${styles.circle} h-9 w-9 -ml-8 flex items-center justify-center`}
    >
      <div className={`${styles.svgIcon} inline-block m-0 p-0`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 20 20"
        >
          <title>user avatar</title>
          <path d="M10 12.5c-5.92 0-9 3.5-9 5.5v1h18v-1c0-2-3.08-5.5-9-5.5z" />
          <circle cx="10" cy="6" r="5" />
        </svg>
      </div>
    </div>
  </div>
)

const mapDispatchToProps = (dispatch) => {
  return {
    displaySignupForm: () =>
      dispatch({
        type: 'SHOW_SIGNUP_MODAL',
      }),
  }
}

export default connect(null, mapDispatchToProps)(SignInIcon)
