import { useState } from 'react'
import { connect } from 'react-redux'

import styles from './SignupModal.module.scss'
import LoginForm from '../LoginForm/LoginForm'

const SignupModal = ({ displaySignupModal, hideSignupModal }) => {
  const [tab, setTab] = useState('login')

  return (
    <div>
      {displaySignupModal ? (
        <div className={`${styles.background} fixed inset-0 overflow-y-auto`}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden lg:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div
              className={`${styles.signupModal} inline-block align-bottom bg-transparent text-left overflow-hidden 
                    shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-lg sm:w-full`}
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="flex justify-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width={24}
                  className="text-white cursor-pointer"
                  onClick={hideSignupModal}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className={`${styles.body} bg-white`}>
                <div className="w-full">
                  <ul className={`${styles.cardsHeader} flex cursor-pointer`}>
                    <li
                      className={`${styles.cardName} ${
                        tab === 'login' ? styles.active : styles.disabled
                      }  
                                    w-3/6 text-center text-2xl items-center flex justify-center font-bold cursor-pointer`}
                      onClick={() => setTab('login')}
                    >
                      Ulogujte se
                    </li>
                    <li
                      className={`${styles.cardName} ${
                        tab === 'register' ? styles.active : styles.disabled
                      } 
                                    w-3/6 text-center text-2xl flex items-center justify-center font-bold`}
                      onClick={() => setTab('register')}
                    >
                      Registrujte se
                    </li>
                  </ul>
                </div>
                <div className={styles.form}>
                  {tab === 'login' ? (
                    <LoginForm hideSignupModal={hideSignupModal} />
                  ) : (
                    <RegisterForm hideSignupModal={hideSignupModal} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

import RegisterForm from '../RegisterForm/RegisterForm'
import { actions } from '../../../store'

const mapStateToProps = (state) => ({
  displaySignupModal: state.displaySignupModal,
})

const mapDispatchToProps = (dispatch) => {
  return {
    hideSignupModal: () => {
      dispatch({
        type: actions.HIDE_SIGNUP_MODAL,
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupModal)
