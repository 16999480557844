import { Array, Record, Static } from 'runtypes'

import styles from './NavBar.module.scss'
import NavBarItem, { NavBarItemElement } from '../NavBarItem/NavBarItem'
import NavBarSearchItem from '../NavBarSearchItem/NavBarSearchItem'
import { Category } from '../../../types/category'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

const NavBarProps = Record({
  categories: Array(Category),
})
type NavBarProps = Static<typeof NavBarProps>

const NavBar = ({ categories }: NavBarProps) => {
  NavBarProps.check({
    categories,
  })

  /* const [showDropDown, setShowDropDown] = useState(false)*/
  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => setScreenWidth(window.innerWidth), [])

  const getTabItem = (tabName: string): NavBarItemElement => {
    return {
      text: tabName,
      hideMargin: false,
    }
  }

  const renderNavigationForBigScreens = () => {
    return (
      <>
        <div className="flex items-center w-full h-12">
          <div className="flex w-11/12 overflow-x-scroll overflow-y-hidden h-12">
            <NavBarItem {...getTabItem('Naslovna')} />
            {categories
              .filter((category) => category.navbar)
              .map((category) => {
                const tabItem = getTabItem(category.name)
                return <NavBarItem key={category.id} {...tabItem} />
              })}
            <NavBarItem {...getTabItem('Impresum')} />
            <NavBarItem {...getTabItem('Pravila komentarisanja')} />
            <NavBarItem {...getTabItem('Marketing')} />
            <NavBarItem {...getTabItem('Uslovi korišćenja')} />
          </div>
          <div className="w-1/12 justify-end flex">
            {/*categories.slice(10).length ? (
              <span
                className="text-white mr-5 cursor-pointer"
                onClick={() => setShowDropDown(!showDropDown)}
              >
                &hellip;
              </span>
            ) : null*/}
            {/*showDropDown ? (
              <div className={`${styles.dropDown} absolute mt-10 p-0 m-0`}>
                {categories.slice(10).map((category) => {
                  const tabItem = getTabItem(category.name)
                  return (
                    <NavBarItem
                      hideMargin={true}
                      key={category.id}
                      {...tabItem}
                    />
                  )
                })}
              </div>
              ) : null*/}
            <NavBarSearchItem />
          </div>
        </div>
      </>
    )
  }

  const renderNavigationForSmallScreens = () => {
    return (
      <div className="flex items-center w-full h-12">
        <div className="flex w-full">
          <NavBarItem {...getTabItem('Naslovna')} />
          {categories
            .filter((category) => category.navbar)
            .map((category) => {
              const tabItem = getTabItem(category.name)
              return <NavBarItem key={category.id} {...tabItem} />
            })}
          {categories
            .filter((category) => !category.navbar)
            .map((category) => {
              const tabItem = getTabItem(category.name)
              return <NavBarItem key={category.id} {...tabItem} />
            })}
          <NavBarItem {...getTabItem('Impresum')} />
          <NavBarItem {...getTabItem('Pravila komentarisanja')} />
          <NavBarItem {...getTabItem('Marketing')} />
          <NavBarItem {...getTabItem('Uslovi korišćenja')} />
        </div>
      </div>
    )
  }

  return (
    <div className={`${styles.navBar} flex w-full`}>
      <div
        className={`${styles.navBarWrapper} mx-auto px-0 h-12 w-10/12 lg:w-full ml-0`}
      >
        <nav className={`${styles.navBar} mx-auto px-0 md:px-5 o`}>
          <div className="relative flex justify-between h-12">
            <div
              className={`${styles.scrollbar} flex w-full justify-center md:px-0 mx-0`}
            >
              {categories.length
                ? screenWidth && screenWidth < 1024
                  ? renderNavigationForSmallScreens()
                  : renderNavigationForBigScreens()
                : null}
            </div>
          </div>
        </nav>
      </div>
      <div className="lg:hidden w-1/12 flex justify-end mr-5">
        <NavBarSearchItem />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  categories: state.categories,
})

export default connect(mapStateToProps)(NavBar)
