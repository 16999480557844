import styles from './DnevnePlus.module.scss'
import Link from 'next/link'

const DnevnePLus = (): JSX.Element => {
  return (
    <Link href="/dnevne-plus">
      <div
        className={`${styles.dnevnePlusWrapper} flex items-center ml-8 cursor-pointer`}
      >
        <div
          className={`${styles.dnevne} rounded-3xl pl-3 pr-10 h-6 items-center hidden lg:flex`}
        >
          <p className="text-white">Dnevno</p>
        </div>
        <div
          className={`${styles.circle} h-9 w-9 -ml-8 flex items-center justify-center`}
        >
          <p className="-mt-1 text-3xl">+</p>
        </div>
      </div>
    </Link>
  )
}

export default DnevnePLus
