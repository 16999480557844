import { Array, String, Record, Static, Union, Null, Partial } from 'runtypes'
import { connect } from 'react-redux'

import styles from './SubNavBar.module.scss'
import { Category } from '../../../types/category'
import SubNavBarItem from '../SubNavBarItem/SubNavBarItem'
import { useEffect, useState } from 'react'

const SubNavBarProps = Record({
  showSubNavigationBarForCategory: Union(String, Null),
}).And(
  Partial({
    categories: Array(Category),
    selectedSubCategory: String,
    selectedCategory: Union(Null, Category),
  })
)
type SubNavBarProps = Static<typeof SubNavBarProps>

const SubNavBar = ({
  categories,
  showSubNavigationBarForCategory,
  selectedCategory,
}: SubNavBarProps) => {
  SubNavBarProps.check({
    categories,
    showSubNavigationBarForCategory,
    selectedCategory,
  })

  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => setScreenWidth(window.innerWidth), [])

  let highlightedCategory = undefined
  if (showSubNavigationBarForCategory) {
    highlightedCategory = categories.find(
      (c) => c.name === showSubNavigationBarForCategory
    )
  } else {
    highlightedCategory = categories.find(
      (c) => selectedCategory && c.name === selectedCategory.name
    )
  }

  const shouldDisplayItems =
    screenWidth > 900
      ? showSubNavigationBarForCategory &&
        highlightedCategory !== undefined &&
        highlightedCategory.subcategories.length
      : highlightedCategory !== undefined &&
        highlightedCategory.subcategories.length

  return (
    <div
      className={`
          ${styles.navBar} 
          ${shouldDisplayItems ? 'block' : 'hidden'} 
          flex w-full absolute
      `}
    >
      <div
        className={`${styles.navBarWrapper} mx-auto px-0 md:px-5 h-8 w-full ml-0`}
      >
        <nav className={`${styles.navBar} mx-auto px-0 md:px-5 `}>
          <div className="relative flex  justify-between h-12 md:h-14">
            <div className="flex justify-center md:px-0 mx-0">
              <div className="flex items-center h-8">
                <div className="flex">
                  {highlightedCategory !== undefined &&
                    highlightedCategory.subcategories.map((subCategory) => {
                      return (
                        <SubNavBarItem
                          key={subCategory.id}
                          text={subCategory.name}
                          categoryName={highlightedCategory.name}
                        />
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  showSubNavigationBarForCategory: state.showSubNavigationBarForCategory,
  categories: state.categories,
  selectedCategory: state.selectedCategory,
})

export default connect(mapStateToProps)(SubNavBar)
