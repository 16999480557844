import {
  Boolean,
  String,
  Static,
  Record,
  Partial,
  Function,
  Union,
  Null,
} from 'runtypes'
import Link from 'next/link'
import { connect } from 'react-redux'

import styles from './NavBarItem.module.scss'
import { actions } from '../../../store'
import { getPageRoute } from '../../../utils/Helpers'
import { Category } from '../../../types'

export const NavBarItemElement = Record({
  text: String,
}).And(
  Partial({
    selectedCategory: Union(Null, Category),
    hideMargin: Boolean,
    setShowSubNavigationBarForCategory: Function,
  })
)

export type NavBarItemElement = Static<typeof NavBarItemElement>

const NavBarItem = ({
  selectedCategory,
  text,
  hideMargin = false,
  setShowSubNavigationBarForCategory,
}: NavBarItemElement) => {
  NavBarItemElement.check({
    selectedCategory,
    text,
    setShowSubNavigationBarForCategory,
  })

  let isSelected = false
  if (!selectedCategory) {
    isSelected = text === 'Naslovna'
  } else {
    isSelected = selectedCategory.name === text
  }

  const navBarItemClickedHandler = () => {
    localStorage.removeItem('scrollTop')
    localStorage.removeItem('posts')
    localStorage.removeItem('pageNumber')
    setShowSubNavigationBarForCategory(text)
  }

  const getUrl = (text) => {
    switch (text) {
      case 'Impresum':
        return '/about/impresum'
      case 'Pravila komentarisanja':
        return '/about/comment-policy'
      case 'Marketing':
        return '/about/marketing'
      case 'Uslovi korišćenja':
        return '/about/uslovi-koriscenja'
      default:
        return `/${getPageRoute(text)}`
    }
  }

  return (
    <div
      onMouseEnter={() => setShowSubNavigationBarForCategory(text)}
      onClick={navBarItemClickedHandler}
      className={`${styles.navItem} ${isSelected ? styles.active : ''}  ${
        hideMargin ? 'mr-0' : ' px-2.5'
      }
      flex flex-row items-center h-12 whitespace-nowrap`}
    >
      <Link href={getUrl(text)}>
        <a className="tracking-normal text-white pt-1 px-1"> {text} </a>
      </Link>
    </div>
  )
}

const mapStateToProps = (state) => ({
  selectedCategory: state.selectedCategory,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setShowSubNavigationBarForCategory: (category) =>
      dispatch({
        type: actions.SET_SHOW_SUB_NAVIGATION_BAR_FOR_CATEGORY,
        payload: {
          category,
        },
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBarItem)
