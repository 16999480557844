import { connect } from 'react-redux'

import styles from './NavBarSearchItem.module.scss'
import { actions } from '../../../store'
import { Function, Record, Static } from 'runtypes'

export const SearchBarProps = Record({
  toggleSearchBar: Function,
})

export type SearchBarProps = Static<typeof SearchBarProps>

const NavBarSearchItem = ({ toggleSearchBar }) => (
  <svg
    className={`${styles.searchIcon} flex items-center cursor-pointer pt-1`}
    onClick={toggleSearchBar}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
    />
  </svg>
)

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSearchBar: () =>
      dispatch({
        type: actions.TOGGLE_SEARCH_BAR,
      }),
  }
}

export default connect(null, mapDispatchToProps)(NavBarSearchItem)
