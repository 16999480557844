import { connect } from 'react-redux'
import {
    Record,
    Function,
    Boolean,
    Static,
    Number
} from 'runtypes'
import { removeCookies } from 'cookies-next'

import styles from './ConfirmDeleteAccountModal.module.scss'
import { actions } from '../../../store'
import { useState } from 'react';
import authentication from '../../../api/authentication';

const ConfirmDeleteAccountModalProps = Record({
    setIsUserLoggedIn: Function,
    setUser: Function,
    toggleLoggedUserMenuModal: Function,
    toggleConfirmDeleteAccountModal: Function,
    userId: Number,
    showConfirmDeleteAccountModal: Boolean,
})

type ConfirmDeleteAccountModalProps = Static<typeof ConfirmDeleteAccountModalProps>

const ConfirmDeleteAccountModal = ({
    setIsUserLoggedIn,
    setUser,
    toggleLoggedUserMenuModal,
    toggleConfirmDeleteAccountModal,
    userId,
    showConfirmDeleteAccountModal,
}: ConfirmDeleteAccountModalProps) => {
    const [number] = useState(Math.floor(Math.random() * 1000));
    const [inputNumber, setInputNumber] = useState(0);

    const deleteAccount = async () => {
        await authentication.destroy(userId);
        toggleConfirmDeleteAccountModal();
        logout();
    };

    const logout = () => {
        setIsUserLoggedIn(false)
        setUser(null)
        removeCookies(null, 'access_token')
        toggleLoggedUserMenuModal()
    }

    if (showConfirmDeleteAccountModal) {
        return (
            <div className={`${styles.confirmDeleteAccountModal} fixed z-10 left-0 top-0`}>
                <div className={`${styles.content} bg-white w-6/12 pt-2 px-4 pb-4 flex flex-col`}>
                    <h2 style={{ color: 'black', fontSize: 20, letterSpacing: 0, fontFamily: 'lato-bold', textAlign: 'center' }}>
                        Obriši profil
                    </h2>
                    <p style={{ color: 'black', fontSize: 18, letterSpacing: 0, fontFamily: 'lato-semibold', textAlign: 'center', marginTop: 10 }}>
                        Da li ste sigurni da želite da obrišete Vaš profil?
                    </p>
                    <p style={{ color: 'black', fontSize: 14, letterSpacing: 0, fontFamily: 'lato-semibold', textAlign: 'center', marginTop: 10 }}>
                        Ukoliko želite, molimo Vas potvrdite to unosom broja koji vidite u polje ispod.
                    </p>
                    <div style={{ alignSelf: 'center' }}>
                        <div style={{
                            width: 80,
                            height: 80,
                            borderRadius: 40,
                            border: '2px solid black',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 10
                        }}>
                            <span style={{
                                color: 'black',
                                fontSize: 30,
                                letterSpacing: 0,
                                fontFamily: 'lato-bold',
                                textAlign: 'center'
                            }}>
                                {number}
                            </span>
                        </div>
                    </div>
                    <div style={{ alignSelf: 'center' }}>
                        <input
                            type="number"
                            inputMode="numeric"
                            value={inputNumber === 0 ? "" : inputNumber}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                    if (value === '') {
                                        setInputNumber(0);
                                    } else {
                                        setInputNumber(parseInt(value));
                                    }
                                }
                            }}
                            className={styles.numberInput}
                            style={{
                                marginTop: 10,
                                borderBottom: '1px solid black',
                                width: 80,
                                fontSize: 30,
                                fontFamily: 'lato-bold',
                                textAlign: 'center'
                            }}
                        />
                    </div>
                    <p style={{
                        color: '#ed1c24',
                        fontSize: 14,
                        letterSpacing: 0,
                        fontFamily: 'lato-semibold',
                        textAlign: 'center',
                        marginTop: 10
                    }}>
                        UPOZORENJE: Brisanjem naloga se uklanjaju sve Vaše aktivnosti uključujući komentare.
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                        <button
                            disabled={number !== inputNumber}
                            onClick={() => {
                                deleteAccount();
                            }}
                            style={{
                                height: 44,
                                marginTop: 8,
                                marginBottom: 8,
                                backgroundColor: number === inputNumber ? '#ed1c24' : '#ec7176',
                                borderRadius: 20,
                                width: '48%',
                                fontFamily: 'lato-semibold',
                                fontSize: 16,
                                fontWeight: 'bold',
                                color: 'white',
                                lineHeight: '44px',
                                textAlign: 'center'
                            }}
                        >Da</button>
                        <button
                            onClick={toggleConfirmDeleteAccountModal}
                            style={{
                                height: 44,
                                marginTop: 8,
                                marginBottom: 8,
                                backgroundColor: '#ed1c24',
                                borderRadius: 20,
                                width: '48%',
                                fontFamily: 'lato-semibold',
                                fontSize: 16,
                                fontWeight: 'bold',
                                color: 'white',
                                lineHeight: '44px',
                                textAlign: 'center'
                            }}
                        >Ne</button>
                    </div>
                </div>
            </div>
        )
    }
    return null
}

const mapStateToProps = (state) => ({
    showConfirmDeleteAccountModal: state.showConfirmDeleteAccountModal,
    userId: state.user?.id,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setIsUserLoggedIn: (isUserLoggedIn) =>
            dispatch({
                type: actions.SET_IS_USER_LOGGED_IN,
                payload: {
                    isUserLoggedIn,
                },
            }),
        setUser: (user) =>
            dispatch({
                type: actions.SET_USER,
                payload: {
                    user,
                },
            }),
        toggleLoggedUserMenuModal: () =>
            dispatch({
                type: actions.TOGGLE_LOGGED_USER_MENU_MODAL,
            }),
        toggleConfirmDeleteAccountModal: () =>
            dispatch({
                type: actions.TOGGLE_CONFIRM_DELETE_ACCOUNT_MODAL,
            }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteAccountModal)
