import { connect } from 'react-redux'
import { Array, Record, String, Number, Static, Function } from 'runtypes'

import styles from './Weather.module.scss'
import WeatherApi from '../../../api/weather'
import { actions } from '../../../store'

const WeatherData = Record({
  main: Record({
    temp: Number,
  }),
  weather: Array(
    Record({
      id: Number,
      main: String,
      description: String,
      icon: String,
    })
  ),
})
type WeatherData = Static<typeof WeatherData>

const Weather = ({ weatherData, setWeatherData }: WeatherProps) => {
  const getWeatherData = async (): Promise<void> => {
    const weatherResponse = await WeatherApi.getWeatherData()
    setWeatherData(weatherResponse.data)
  }

  const getWeatherIcon = (): string | null => {
    if (!weatherData) return null
    const { weather } = weatherData
    return weather.length
      ? `https://openweathermap.org/img/w/${weather[0].icon}.png`
      : null
  }

  if (!weatherData) {
    getWeatherData()
  }

  const weatherIcon = getWeatherIcon()
  return (
    <>
      <div
        className={`${styles.weather} inline-block float-right relative py-1 top-1/2 flex items-center mr-6 lg:mr-0 h-full my-0 py-0s`}
      >
        <p className={`${styles.divider} text-2xl mx-5 hidden lg:block`}>|</p>
        <div>
          <p className={styles.vrijeme}>Vrijeme</p>
          <p className={styles.podgorica}>Podgorica</p>
        </div>
        <img className={styles.icon} src={weatherIcon} />
        <p className="font-black text-xl lg:text-base tracking-tighter">
          {weatherData && weatherData.main && weatherData.main.temp
            ? Math.round(weatherData.main.temp)
            : null}
          <span>&#176;</span>C
        </p>
      </div>
    </>
  )
}
const WeatherProps = Record({
  weatherData: WeatherData,
  setWeatherData: Function,
})

type WeatherProps = Static<typeof WeatherProps>

const mapStateToProps = (state) => {
  return {
    weatherData: state.weatherData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setWeatherData: (data) => {
      dispatch({
        type: actions.SET_WEATHER_DATA,
        payload: {
          data,
        },
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Weather)
