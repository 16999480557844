import styles from './Logo.module.scss'
import Link from 'next/link'

interface Props {
  preventLink?: boolean
}

const Logo = ({ preventLink }: Props) => (
  <div className="flex items-center">
    {preventLink ? (
      <img
        src="/logo.png"
        className={`${styles.logo} cursor-pointer`}
        alt="logo"
      />
    ) : (
      <Link href="/">
        <img
          src="/logo.png"
          className={`${styles.logo} cursor-pointer`}
          alt="logo"
        />
      </Link>
    )}
  </div>
)

export default Logo
