import { connect } from 'react-redux'
import { Record, Function, Boolean, Static } from 'runtypes'
import { removeCookies } from 'cookies-next'

import styles from './LoggedUserMenuModal.module.scss'
import { actions } from '../../../store'

const LoggedUserMenuModalProps = Record({
  setIsUserLoggedIn: Function,
  setUser: Function,
  toggleLoggedUserMenuModal: Function,
  toggleConfirmDeleteAccountModal: Function,
  showLoggedUserMenuModal: Boolean,
})

type LoggedUserMenuModalProps = Static<typeof LoggedUserMenuModalProps>

const LoggedUserMenuModal = ({
  setIsUserLoggedIn,
  setUser,
  toggleLoggedUserMenuModal,
  toggleConfirmDeleteAccountModal,
  showLoggedUserMenuModal,
}: LoggedUserMenuModalProps) => {
  const logout = () => {
    setIsUserLoggedIn(false)
    setUser(null)
    removeCookies(null, 'access_token')
    toggleLoggedUserMenuModal()
  }

  if (showLoggedUserMenuModal) {
    return (
      <div className={`${styles.loggedUserMenuModal} fixed z-10 left-0 top-0`}>
        <div
          className={`${styles.content} bg-white w-6/12 pt-2 px-4 pb-4 flex flex-col`}
        >
          <span
            className={`${styles.close} bold flex justify-end cursor-pointer`}
            onClick={toggleLoggedUserMenuModal}
          >
            &times;
          </span>
          <div className="mt-8 flex justify-center">
            <div className={`${styles.svgIcon} inline-block m-0 p-0`}>
              <svg viewBox="0 0 20 20" className="w-32 h-32">
                <title>user avatar</title>
                <path d="M10 12.5c-5.92 0-9 3.5-9 5.5v1h18v-1c0-2-3.08-5.5-9-5.5z" />
                <circle cx="10" cy="6" r="5" />
              </svg>
            </div>
          </div>
          <div
              className="text-center mb-4 mt-8 cursor-pointer text-red-600"
              onClick={toggleConfirmDeleteAccountModal}
          >
            <p>Brisanje profila</p>
          </div>
          <div
            className="text-center mb-4 mt-4 cursor-pointer"
            onClick={logout}
          >
            <p>Odjavi se</p>
          </div>
        </div>
      </div>
    )
  }
  return null
}

const mapStateToProps = (state) => ({
  showLoggedUserMenuModal: state.showLoggedUserMenuModal,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setIsUserLoggedIn: (isUserLoggedIn) =>
      dispatch({
        type: actions.SET_IS_USER_LOGGED_IN,
        payload: {
          isUserLoggedIn,
        },
      }),
    setUser: (user) =>
      dispatch({
        type: actions.SET_USER,
        payload: {
          user,
        },
      }),
    toggleLoggedUserMenuModal: () =>
      dispatch({
        type: actions.TOGGLE_LOGGED_USER_MENU_MODAL,
      }),
    toggleConfirmDeleteAccountModal: () =>
      dispatch({
        type: actions.TOGGLE_CONFIRM_DELETE_ACCOUNT_MODAL,
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoggedUserMenuModal)
