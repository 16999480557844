import Head from 'next/head'
import { Provider } from 'react-redux'

import { useStore } from '../store'
import '../styles/global.css'
import 'tailwindcss/tailwind.css'
import '../styles/no-more-tailwind.css' // for hardcoded pages that need basic look
import 'react-image-gallery/styles/css/image-gallery.css'
import SignupModal from '../components/Content/SignupModal/SignupModal'
import Header from '../components/Header/Header'
import BannerModal from '../components/Content/BannerModal/BannerModal'
import { getFileFullPath, isLinkForVideo } from '../utils/Helpers'
import LoggedUserMenuModal from '../components/Header/LoggedUserMenuModal/LoggedUserMenuModal'
import VideoModal from '../components/Content/VideoModal/VideoModal'
import ConfirmDeleteAccountModal from '../components/Header/ConfirmDeleteAccountModal/ConfirmDeleteAccountModal';

function Home({ Component, pageProps }) {
  const tags = pageProps.initialReduxState?.post?.tags
  const title = pageProps.initialReduxState?.post?.name
  const store = useStore(pageProps.initialReduxState)
  const image = pageProps.initialReduxState?.post?.featured_image ?? null
  const isFeaturedVideo = isLinkForVideo(image)

  return (
    <Provider store={store}>
      <Head>
        <title>{title ? title : 'Dnevno.me'}</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin={`true`}
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Asap:wght@700&display=swap"
        />
        {tags ? <meta name="keywords" content={tags} /> : null}
        {title ? <meta name="title" content={title} /> : null}
        {title ? <meta name="ok:title" content={title} /> : null}
        {image ? (
          isFeaturedVideo ? (
            <meta property="og:video" content={getFileFullPath(image)} />
          ) : (
            <meta property="og:image" content={getFileFullPath(image)} />
          )
        ) : null}
        {/*Global site tag (gtag.js) - Google Analytics*/}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-221881489-1"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-221881489-1');
            `,
          }}
        ></script>
      </Head>
      <div className="app bg-cover h-full">
        <main>
          <SignupModal />
          <LoggedUserMenuModal />
          <ConfirmDeleteAccountModal />
          <Header />
          <BannerModal />
          <Component {...pageProps} />
          <VideoModal />
        </main>
      </div>
    </Provider>
  )
}

export default Home
