import { useState } from 'react'
import { connect } from 'react-redux'
import { setCookies } from 'cookies-next'
import Link from 'next/link'

import styles from './LoginForm.module.scss'
import Authentication from '../../../api/authentication'
import config from '../../../config.json'
import { Function, Record, Static } from 'runtypes'
import * as Sentry from '@sentry/nextjs'
import authentication from '../../../api/authentication'

export const LoginFormProps = Record({
  setIsUserLoggedIn: Function,
  hideSignupModal: Function,
  setUser: Function,
})

export type LoginFormProps = Static<typeof LoginFormProps>

const LoginForm = ({
  setIsUserLoggedIn,
  setUser,
  hideSignupModal,
}: LoginFormProps) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  // @TODO remove this
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  const [loginError, setLoginError] = useState(null)
  const [forgotPasswordError, setForgotPasswordError] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [rememberMe, setRememberMe] = useState(true)

  const login = async (e) => {
    e.preventDefault()
    try {
      const response = await Authentication.login(username, password)
      if (response.status === 200) {
        setIsUserLoggedIn(true)
        setUser(response.data.user)
        setLoginError(null)
        setCookies(null, 'access_token', response.data.access_token)
        hideSignupModal()
      }
    } catch (e) {
      setLoginError('Uneti podaci nisu ispravni')
      Sentry.captureException(e)
    }
  }

  const showIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        onClick={() => setShowPassword(true)}
        className="h-6 cursor-pointer"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
        />
      </svg>
    )
  }

  const hideIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        onClick={() => setShowPassword(false)}
        stroke="currentColor"
        className="h-6 cursor-pointer"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
        />
      </svg>
    )
  }

  const toggleRememberMe = () => setRememberMe(!rememberMe)

  const forgotPassword = async () => {
    if (username.length) {
      setForgotPasswordError(null)
      await authentication.forgotPassword(username)
      hideSignupModal()
    } else {
      setForgotPasswordError('Email je obavezan')
    }
  }

  return (
    <div className={styles.loginForm}>
      <form onSubmit={(e) => login(e)}>
        <div className="leading-5">
          <label htmlFor="username" className={styles.label}>
            {username.length ? `Username / email` : <div>&nbsp;</div>}
          </label>
          <input
            className={`outline-none border-b-2 ${
              forgotPasswordError ? styles.emailRequired : ''
            }`}
            type="text"
            placeholder="Email"
            checked={false}
            value={username}
            required
            onChange={(e) => {
              setForgotPasswordError(null)
              setUsername(e.target.value)
            }}
          />
          {forgotPasswordError ? (
            <span className={styles.loginError}>{forgotPasswordError}</span>
          ) : null}
        </div>
        <div className="leading-5">
          <label htmlFor="password" className={styles.label}>
            {password.length ? `Password` : <div>&nbsp;</div>}
          </label>
          <div className="relative">
            <input
              className="outline-none border-b-2 w-full pr-12"
              placeholder="Šifra"
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className={`${styles.eyeIcon} absolute inset-y-0 right-0 pr-2 flex items-center text-sm leading-5 pb-10 h-2`}
            >
              <p>{showPassword ? hideIcon() : showIcon()}</p>
            </div>
          </div>
        </div>
        <div
          className={`${styles.keepMeLoggedInWrapper} flex flex-row items-center`}
        >
          <div className="flex flex-row items-center">
            <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              className="mr-2"
              onChange={toggleRememberMe}
            />
            <label
              htmlFor="rememberMe"
              className={`${styles.keepMeLoggedIn} cursor-pointer`}
            >
              Zapamti me
            </label>
          </div>
          <div className={`${styles.forgotPassword} ml-auto`}>
            <p className="cursor-pointer" onClick={forgotPassword}>
              Zaboravljena šifra?
            </p>
          </div>
        </div>
        {loginError ? (
          <span className={styles.loginError}>{loginError}</span>
        ) : null}
        <button
          className={`${styles.loginButton} text-white font-semibold w-full h-11 my-2`}
        >
          ULOGUJTE SE
        </button>
        <div className="flex my-2 items-center justify-center">
          <div>
            <Link href={`${config.baseUrl}/auth/facebook/login`}>
              <img
                src="/facebook.svg"
                className="mr-5 h-10 w-10 cursor-pointer"
                alt="facebook icon"
              />
            </Link>
          </div>
          <div>
            <Link href={`${config.baseUrl}/auth/twitter/login`}>
              <img
                src="/twitter.svg"
                className="mx-5 h-10 w-10 cursor-pointer"
                alt="twitter icon"
              />
            </Link>
          </div>
          <div>
            <Link href={`${config.baseUrl}/auth/google/login`}>
              <img
                src="/google.svg"
                className="mx-5 h-10 w-10 cursor-pointer"
                alt="google icon"
              />
            </Link>
          </div>
        </div>
      </form>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsUserLoggedIn: (isUserLoggedIn) =>
      dispatch({
        type: 'SET_IS_USER_LOGGED_IN',
        payload: {
          isUserLoggedIn,
        },
      }),
    setUser: (user) =>
      dispatch({
        type: 'SET_USER',
        payload: {
          user,
        },
      }),
  }
}

export default connect(null, mapDispatchToProps)(LoginForm)
