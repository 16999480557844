import React from 'react'
import config from '../../../config.json'

const TestEnvHeaderMessage = (): JSX.Element | null => {
  if (config.env !== 'production') {
    return (
      <p
        style={{ backgroundColor: 'red', color: 'white', textAlign: 'center' }}
      >
        APLIKACIJA JE POKRENUTA NA{' '}
        <span style={{ color: 'yellow' }}>{config.env}</span> ENVIRONMENTU
      </p>
    )
  }
  return null
}

export default TestEnvHeaderMessage
