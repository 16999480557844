import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import HeaderBar from './HeaderBar/HeaderBar'
import NavBar from './NavBar/NavBar'
import SubNavBar from './SubNavBar/SubNavBar'
import CategoryApi from '../../api/categories'
import SearchBar from './SearchBar/SearchBar'
import { actions } from '../../store'
import AppDownloadHeader from './AppDownloadHeader/AppDownloadHeader'
import TestEnvHeaderMessage from './TestEnvHeaderMessage/TestEnvHeaderMessage'

const Header = ({
  categories,
  setCategories,
  setShowSubNavigationBarForCategory,
  displaySearchBar,
  showBanner,
}) => {
  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => setScreenWidth(window.innerWidth), [])

  const fetchCategories = async () => {
    const categoriesResponse = await CategoryApi.getCategories()
    setCategories(categoriesResponse.data)
  }

  if (!categories.length) {
    fetchCategories()
  }

  return (
    <div className="bg-white fixed w-full z-10">
      {screenWidth < 900 && <AppDownloadHeader />}
      <TestEnvHeaderMessage />
      <HeaderBar />
      {displaySearchBar ? (
        <SearchBar />
      ) : (
        <div onMouseLeave={() => setShowSubNavigationBarForCategory(null)}>
          {!showBanner ? (
            <>
              <NavBar />
              <SubNavBar />
            </>
          ) : (
            <>
              <div className="flex items-center w-full h-1 bg-red-700" />
              <div className="flex items-center w-full h-1 " />
            </>
          )}
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCategories: (categories) =>
      dispatch({
        type: 'SET_CATEGORIES',
        payload: {
          categories,
        },
      }),
    setShowSubNavigationBarForCategory: (category) =>
      dispatch({
        type: actions.SET_SHOW_SUB_NAVIGATION_BAR_FOR_CATEGORY,
        payload: {
          category,
        },
      }),
  }
}

const mapStateToProps = (state) => ({
  categories: state.categories,
  displaySearchBar: state.displaySearchBar,
  showBanner: state.showBanner,
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
