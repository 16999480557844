import { connect } from 'react-redux'
import { actions } from '../../../store'
import { getQueryParameterByName } from '../../../utils/Helpers'

type VideoModalProps = {
  displayVideoModal: boolean
  videoUrl: string | null
  hideVideoModal?: () => {}
}

function VideoModal({
  displayVideoModal,
  hideVideoModal,
  videoUrl,
}: VideoModalProps) {
  return displayVideoModal ? (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80"
      onClick={hideVideoModal}
    >
      <div className="absolute inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
        <iframe
          className="w-8/12 h-4/6"
          width={400}
          src={`https://www.youtube.com/embed/${getQueryParameterByName(
            'v',
            videoUrl
          )}?autoplay=1`}
          frameBorder="0"
          allow="autoplay"
        />
      </div>
    </div>
  ) : null
}

const mapStateToProps = (state) => ({
  displayVideoModal: state.displayVideoModal,
  videoUrl: state.videoUrl,
})

const mapDispatchToProps = (dispatch) => {
  return {
    hideVideoModal: () => {
      dispatch({
        type: actions.HIDE_VIDEO_MODAL,
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoModal)
