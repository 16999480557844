import { Union, String, Static, Null, Record, Partial } from 'runtypes'
import Link from 'next/link'

import styles from './SubNavBarItem.module.scss'
import { formatUrl } from '../../../utils/Helpers'
import { connect } from 'react-redux'
import { Category } from '../../../types'

export const NavBarItemElement = Record({
  text: String,
  categoryName: String,
}).And(
  Partial({
    selectedSubCategory: Union(Null, Category),
    selectedCategory: Union(Null, Category),
  })
)

export type NavBarItemElement = Static<typeof NavBarItemElement>

const SubNavBarItem = ({
  text,
  categoryName,
  selectedSubCategory,
  selectedCategory,
}: NavBarItemElement) => {
  NavBarItemElement.check({
    text,
    categoryName,
    selectedSubCategory,
    selectedCategory,
  })

  return (
    <div
      className={`
      ${styles.navItem} 
      ${
        selectedSubCategory && selectedSubCategory.name === text
          ? styles.active
          : ''
      }
      flex flex-row items-center h-8 whitespace-nowrap px-2.5`}
    >
      <Link href={`/${formatUrl(`${categoryName}/${text}`)}`}>
        <a className="pt-1 px-1"> {text} </a>
      </Link>
    </div>
  )
}

const mapStateToProps = (state) => ({
  selectedCategory: state.selectedCategory,
  selectedSubCategory: state.selectedSubCategory,
})

export default connect(mapStateToProps)(SubNavBarItem)
