import { connect } from 'react-redux'

import styles from './HeaderBar.module.scss'
import Logo from '../Logo/Logo'
import Weather from '../Weather/Weather'
import LoggedUser from '../LoggedUser/LoggedUser'
import DateAndTime from '../DateAndTime/DateAndTime'
import SignInIcon from '../SignInIcon/SignInIcon'
import DnevnePlus from '../DnevnePlus/DnevnePlus'
import { actions } from '../../../store'
import { IoChevronForwardSharp } from 'react-icons/io5'
import { useEffect, useState } from 'react'

const HeaderBar = ({
  isUserLoggedIn,
  user,
  showBanner,
  modalHidden,
  setBannerModalHidden,
  setShowBanner,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsSmallScreen(window.innerWidth < 450)
    }
  }, [])

  const hideBanner = () => {
    setBannerModalHidden(true)
    setShowBanner(false)
  }

  if (showBanner && !modalHidden) {
    return (
      <div
        className={`${styles.headerBarSimple} mx-0 px-5 lg:mx-auto lg:px-5 flex items-center justify-between`}
      >
        <div
          className={`flex flex-row ${
            !isSmallScreen ? 'w-1/2' : 'w-2/5'
          } h-full`}
          onClick={hideBanner}
        >
          <Logo preventLink={true} />
        </div>
        <div className="flex flex-row h-full w-auto">
          <div className="flex flex-row justify-end">
            <div
              className="flex flex-row h-full pr-1 align-middle justify-center"
              onClick={hideBanner}
            >
              <div
                className={`${styles.continueButton} ${
                  isSmallScreen ? styles.continueButtonSmall : ''
                } font-bold flex items-center justify-center pt-0.5`}
              >
                NASTAVI NA DNEVNO
              </div>
              <div
                className={`${styles.cursorPointer} flex items-center justify-center`}
                onClick={hideBanner}
              >
                <IoChevronForwardSharp size={23} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className={`${styles.headerBar} mx-0 px-5 lg:mx-auto lg:px-5 flex items-center justify-between`}
      >
        <div className="flex flex-row w-1/2 h-full">
          <Logo />
          <div className="hidden lg:flex flex-row items-center">
            <DateAndTime />
            <Weather />
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex flex-row">
            <div className="mr-2">
              <DnevnePlus />
            </div>
            <div>
              {!isUserLoggedIn ? <SignInIcon /> : <LoggedUser user={user} />}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isUserLoggedIn: state.isUserLoggedIn,
  user: state.user,
  showBanner: state.showBanner,
  modalHidden: state.bannerModalHidden,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setBannerModalHidden: (modalHidden) => {
      dispatch({
        type: actions.SET_BANNER_MODAL_HIDDEN,
        payload: {
          bannerModalHidden: modalHidden,
        },
      })
    },
    setShowBanner: (showBannerArg) => {
      dispatch({
        type: actions.SET_SHOW_BANNER,
        payload: {
          showBanner: showBannerArg,
        },
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBar)
