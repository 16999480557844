import ApiService from './api'

export default {
  getUncategorizedBanners() {
    return ApiService.get('/banners')
  },

  getBannersByCategoryId(categoryId) {
    return ApiService.get(`/banners/category/${categoryId}`)
  },

  getCoverBanner() {
    return ApiService.get(`/banners/cover-page`)
  },
}
