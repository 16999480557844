import React from 'react'

const AppDownloadHeader = (): JSX.Element | null => {
  return (
    <div
      style={{
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: 'red',
        backgroundAttachment: 'fixed',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <div
          style={{
            width: 'fit-content',
            margin: '0 auto',
            animation: 'marquee 30s linear infinite',
            transform: 'translateX(-50%)', // Dodato: pomeranje na sredinu ekrana
          }}
        >
          <p
            style={{
              color: 'white',
              whiteSpace: 'nowrap',
            }}
          >
            Preuzmite našu aplikaciju,{' '}
            <span style={{ color: 'yellow' }}>Dnevno.me</span> - Tvoja dobra
            navika!
            <a
              href="https://apps.apple.com/us/app/dnevno-me/id1584247258"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              App store
            </a>{' '}
            |{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.dnevne.dnevne"
              target="_blank"
              rel="noreferrer"
            >
              Play Store
            </a>
          </p>
        </div>
      </div>

      <style>{`
        @keyframes marquee {
          0% { transform: translateX(100%); }
          100% { transform: translateX(-100%); }
        }
      `}</style>
    </div>
  )
}

export default AppDownloadHeader
