import { Function, Record, Static } from 'runtypes'
import { connect } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import styles from './SearchBar.module.scss'
import { actions } from '../../../store'
import NewsApi from '../../../api/news'

export const SearchBarProps = Record({
  toggleSearchBar: Function,
  setSearchResults: Function,
  setSearchQuery: Function,
})

export type SearchBarProps = Static<typeof SearchBarProps>

const SearchBar = ({
  toggleSearchBar,
  setSearchResults,
  setSearchQuery,
  displaySearchBar,
}: SearchBarProps | any) => {
  const [query, setQuery] = useState('')

  const search = async () => {
    setSearchQuery(query)
    if (!query.length) return
    try {
      const response = await NewsApi.searchByQuery(query)
      if (response.data.data.length) {
        setSearchResults(response.data)
      } else {
        setSearchResults(null)
      }
    } catch (e) {
      setSearchResults(null)
    }
  }

  const toggleSearch = () => {
    toggleSearchBar()
  }

  const searchInputRef = useRef(null)

  useEffect(() => {
    if (displaySearchBar) {
      searchInputRef.current?.focus()
      // eslint-disable-next-line no-console
      console.dir(searchInputRef.current)
    }
  }, [displaySearchBar])

  return (
    <div className={`${styles.searchBar} mx-auto px-0 md:px-5 h-12 md:h-14`}>
      <div
        className={`${styles.searchBarForm} mx-auto px-0 md:px-5 h-12 md:h-14`}
      >
        <div
          className={`${styles.searchBarElementsWrapper} flex flex-row items-center mx-2.5 md:mx-0`}
        >
          <svg
            className={`${styles.closeIcon} cursor-pointer`}
            onClick={toggleSearch}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            height={24}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <input
            ref={searchInputRef}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') search()
            }}
            className={`${styles.input} text-white border-0 outline-none resize-none w-full overflow-hidden h-12 md:h-10 ml-2`}
            placeholder="Pretraga..."
          />
          <svg
            className={`${styles.searchIcon} flex items-center cursor-pointer pt-1`}
            onClick={search}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            width="24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSearchBar: () => {
      dispatch({
        type: actions.TOGGLE_SEARCH_BAR,
      })
    },
    setSearchResults: (results) => {
      dispatch({
        type: actions.SET_SEARCH_RESULTS,
        payload: {
          results,
        },
      })
    },
    setSearchQuery: (query) => {
      dispatch({
        type: actions.SET_SEARCH_QUERY,
        payload: {
          query,
        },
      })
    },
  }
}

const mapStateToProps = (state) => ({
  displaySearchBar: state.displaySearchBar,
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)
