import { useState } from 'react'
import { connect } from 'react-redux'

import styles from './RegisterForm.module.scss'
import { Record, Function, Static } from 'runtypes'
import authentication from '../../../api/authentication'
import * as Sentry from '@sentry/nextjs'

export const RegisterProps = Record({
  hideSignupModal: Function,
})

export type RegisterProps = Static<typeof RegisterProps>

const Register = ({ hideSignupModal }: RegisterProps) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  // @TODO remove this
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [rememberMe, setRememberMe] = useState<boolean>(true)
  const [error, setError] = useState<string>('')

  const registerUser = async (event) => {
    setError('')
    event.preventDefault()
    event.stopPropagation()
    try {
      await authentication.register(email, name, password)
      hideSignupModal()
    } catch (e) {
      if (e === 'Error: Request failed with status code 422') {
        setError('Neispravni podaci, pokušajte drugu kombinaciju.')
      } else {
        setError('Došlo je do greške, pokušajte kasnije.')
      }
      Sentry.captureException(e)
    }
  }

  const showIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        onClick={() => setShowPassword(true)}
        className="h-6 cursor-pointer"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
        />
      </svg>
    )
  }

  const hideIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        onClick={() => setShowPassword(false)}
        stroke="currentColor"
        className="h-6 cursor-pointer"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
        />
      </svg>
    )
  }

  const toggleRememberMe = () => setRememberMe(!rememberMe)

  return (
    <div className={styles.registerForm}>
      <form>
        <div className="leading-5">
          <label htmlFor="name" className={`${styles.label}`}>
            {name.length ? `Korisničko ime` : <div>&nbsp;</div>}
          </label>
          <input
            className="outline-none border-b-2"
            type="text"
            placeholder="Korisničko ime"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="leading-5">
          <label htmlFor="email" className={styles.label}>
            {email.length ? `Email` : <div>&nbsp;</div>}
          </label>
          <input
            className="outline-none	border-b-2"
            type="text"
            placeholder="Email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="leading-5">
          <label htmlFor="password" className={styles.label}>
            {password.length ? `Password` : <div>&nbsp;</div>}
          </label>
          <div className="relative">
            <input
              className="outline-none border-b-2 w-full pr-12"
              placeholder="Šifra"
              value={password}
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="absolute inset-y-0 right-0 pr-2 flex items-center text-sm leading-5 pb-10 pt-5 h-2`">
              <p>{showPassword ? hideIcon() : showIcon()}</p>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="rememberMe"
            checked={rememberMe}
            className="mr-2"
            onChange={toggleRememberMe}
          />
          <label
            htmlFor="rememberMe"
            className={`${styles.keepMeLoggedIn} cursor-pointer`}
          >
            Zapamti me
          </label>
        </div>
        {error.length ? <span className={styles.error}>{error}</span> : null}
        <button
          type="submit"
          className={`${styles.registerButton} text-white font-semibold w-full h-11 mt-5`}
          onClick={registerUser}
        >
          REGISTRUJTE SE
        </button>
      </form>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsUserLoggedIn: (isUserLoggedIn) =>
      dispatch({
        type: 'SET_IS_USER_LOGGED_IN',
        payload: {
          isUserLoggedIn,
        },
      }),
    setUser: (user) =>
      dispatch({
        type: 'SET_USER',
        payload: {
          user,
        },
      }),
  }
}

export default connect(null, mapDispatchToProps)(Register)
