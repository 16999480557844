import ApiService from './api'

export default {
  login(username, password) {
    return ApiService.post(`/user/login`, {
      username,
      password,
    })
  },

  register(email, username, password) {
    return ApiService.post(`/user/register`, {
      name: username,
      username: email,
      password,
    })
  },

  destroy(id, token = null) {
    return ApiService.delete(`/user/delete`, id, token)
  },

  getUser(token = null) {
    return ApiService.get(`/user/profile`, null, null, token)
  },

  getUserFacebook(token) {
    return ApiService.get(`/auth/facebook/me/${token}`, null, null)
  },

  getUserGoogle(token) {
    return ApiService.get(`/auth/google/me/${token}`, null, null)
  },

  confirmAccount(code) {
    return ApiService.post(`/user/verify-account`, {
      /* eslint-disable @typescript-eslint/camelcase */
      verify_token: code,
    })
  },

  forgotPassword(email) {
    return ApiService.post(`/user/forgot-password`, {
      email,
    })
  },

  setNewPassword(token, password) {
    return ApiService.post(`/user/change-password`, {
      token,
      /* eslint-disable @typescript-eslint/camelcase */
      new_password: password,
    })
  },

  getUserTwitter(token: string, secret: string) {
    return ApiService.get(`/auth/twitter/me/${token}/${secret}`, null, null)
  },
}
