import moment from 'moment'

import styles from './DateAndTime.module.scss'

const DateAndTime = () => {
  const getDay = (dayIndex: number): string | null => {
    const days = [
      'Ponedeljak',
      'Utorak',
      'Srijeda',
      'Četvrtak',
      'Petak',
      'Subota',
      'Neđelja',
    ]
    if (dayIndex > 7 || dayIndex < 1) return null
    return days[dayIndex - 1].toUpperCase()
  }

  const getMonth = (monthIndex: number): string | null => {
    const months = [
      'Januar',
      'Februar',
      'Mart',
      'April',
      'Maj',
      'Jun',
      'Jul',
      'Avgust',
      'Septembar',
      'Oktobar',
      'Novembar',
      'Decembar',
    ]
    if (monthIndex > 11 || monthIndex < 0) return null
    return months[monthIndex].toUpperCase()
  }

  return (
    <div
      className={`${styles.dateAndTimeWrapper} ml-4 flex justify-center flex-col`}
    >
      <p className={styles.firstRaw}>{getDay(moment().day())}</p>
      <p className={styles.secondRaw}>
        {`${moment().date()}. ${getMonth(
          moment().month()
        )} ${moment().year()}.`}
      </p>
    </div>
  )
}

export default DateAndTime
